import React from "react"

import { graphql, withPrefix, Link } from "gatsby"
import QRCode from "qrcode.react"

import * as styles from "./styles.module.scss"

const Page = ({ location, data }) => {
  const nodes = data.allFile.nodes.map((node, idx) => (
    <div key={ idx } className={ styles.NodeContainer }>
      <QRCode value={`${ location.protocol}//${location.host}${withPrefix(node.name)}`} className={ styles.QRCode }/>
      <p className={ styles.LinkContainer }>
        <Link to={`/${node.name}`}>{ node.name }</Link>
      </p>
    </div>
  ))
  
  return (
    <div className={ styles.Container }>{ nodes }</div>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    allFile(filter: {internal: {mediaType: {glob: "image/*"}}}, sort: {fields: name}) {
      nodes {
        name
      }
    }
  }
`
